<template>
	<div class="page">
		<div class="title">转正管理</div>

		<!-- filter -->
		<div class="filter_box">
			<div class="filter_search">
				<el-input class="filter_search_input" placeholder="请输入关键词" v-model="search.keyword" clearable>
				</el-input>
				<div class="custom_button no_select" @click="toSearch">
					搜索
				</div>
				<!-- <div class="custom_button no_select">添加员工</div>
				<div class="custom_button plain no_select">办理转正</div> -->
			</div>
		</div>

		<!-- table -->
		<el-table ref="table" :data="tableData" :header-cell-style="tableHeaderStyle" :cell-style="tableCellStyle"
			style="width: 100%;">
			<el-table-column type="selection" width="55"> </el-table-column>
			<el-table-column prop="staff.real_name" label="姓名">
			</el-table-column>
			<el-table-column prop="staff.entry_time" label="入职日期">
			</el-table-column>
			<el-table-column prop="staff.probation_period" label="试用期">
			</el-table-column>
			<el-table-column prop="staff.department_data.department_name" label="部门">
			</el-table-column>
			<el-table-column prop="staff.department_data.station_name" label="岗位">
			</el-table-column>
			<el-table-column prop="staff.job_number" label="工号">
			</el-table-column>
			<el-table-column prop="staff.labour_type_value" label="工作性质">
			</el-table-column>
			<el-table-column prop="regular_time" label="申请时间">
			</el-table-column>
			<!-- <el-table-column prop="formal.approval_number" label="审批编号">
            </el-table-column> -->
			<el-table-column label="操作" width="120">
				<template v-slot:default="scope">
					<el-dropdown size="mini" split-button type="primary">
						操作
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="toRegular([scope.row.id], scope.row.real_name)">办理转正</el-dropdown-item>
							<el-dropdown-item @click.native="editPeriod(scope.row.staff_id)">修改试用期</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> 
					<!-- <div class="operations">
						<div @click="
                                toRegular([scope.row.id], scope.row.real_name)
                            ">
							办理转正
						</div>
						<div @click="editPeriod(scope.row.staff_id)">
							修改试用期
						</div>
					</div> -->
				</template>
			</el-table-column>
		</el-table>

		<div class="bottom_betch_operation">
			<el-select class="betch_select" v-model="batch">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div class="custom_button no_select" @click="toBatchProcessing">
				批量处理
			</div>
		</div>

		<el-pagination layout="prev, pager, next" :total="page.total" :page-size="page.size"
			:current-page.sync="page.page" @current-change="loadData">
		</el-pagination>

		<el-dialog title="修改试用期" :visible.sync="dialogVisible" :close-on-click-modal="false" width="30%">
			<el-form ref="form" :model="form" :rules="rules">
				<el-form-item label="试用期（月）" prop="probation_period">
					<el-input v-model="form.probation_period" type="number"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer" style="display: flex;">
				<div class="custom_button no_select" @click="onPeriodEdit">
					确 定
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";

	export default {
		data() {
			return {
				search: {
					keyword: "",
				},
				// 分页参数
				page: {
					size: 15,
					page: 1,
					total: 0,
				},
				tableData: [],
				form: {
					id: '',
					probation_period: 3,
				},
				rules: {
					probation_period: [{
						required: true,
						message: "请填写试用期",
						trigger: "blur",
					}, ],
				},
				dialogVisible: false,
				batch: 1,
				options: [{
					value: 1,
					label: "办理转正",
				}, ],

				tableHeaderStyle: {
					backgroundColor: "#F5F8FA",
					textAlign: "center",
				},
				tableCellStyle: {
					textAlign: "center",
				},
			};
		},
		created() {
			this.loadData = this.loadDataFactory();
			this.loadData();
		},
		methods: {
			...mapActions("employee", ["getEmployeeRegularList", "postEditPeriod"]),

			loadDataFactory(keyword = null) {
				let params = {};
				if (keyword) {
					params.keywords = keyword;
				}
				return () => {
					params.page = this.page.page;
					let loading = this.$loading();
					this.getEmployeeRegularList(params)
						.then((res) => {
							console.log(res);
							this.tableData = res.data.data.list;
							this.page.total = res.data.data.total;
							this.$nextTick(() => {
								loading.close();
							});
						})
						.catch((e) => {
							this.$message.error(e.msg);
							this.$nextTick(() => {
								loading.close();
							});
						});
				};
			},

			/**
			 * 搜索
			 */
			toSearch() {
				this.page.page = 1;
				// 生产带有搜索参数的函数
				this.loadData = this.loadDataFactory(this.search.keyword);
				this.loadData();
			},

			/**
			 * 修改试用期
			 */
			editPeriod(id) {
				this.form.id = id;
				this.dialogVisible = true;
			},

			onPeriodEdit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						console.log(this.form);
						let loading = this.$loading();
						this.postEditPeriod(this.form)
							.then((res) => {
								console.log(res);
								loading.close();
								this.$message.success(res.data.msg);
								this.$router.back();
							})
							.catch((e) => {
								console.log(e);
								loading.close();
								this.$message.error(e.msg);
							});
					} else {
						this.$notify.error({
							title: "提交失败",
							message: "请先完整填写表单",
						});
						return false;
					}
				});
			},

			/**
			 * 转正
			 */
			toRegular(ids, names) {
				console.log(ids, names);
				this.$router.push({
					name: "EmployeeInsuranceSettings",
					query: {
						names,
						ids: ids.join(","),
					},
				});
			},

			/**
			 * 批量处理
			 */
			toBatchProcessing() {
				let selectedItems = this.$refs.table.store.states.selection;
				console.log(selectedItems);
				if (selectedItems.length > 0) {
					this.toRegular(
						selectedItems.map((item) => item.id),
						selectedItems.map((item) => item.real_name).join(", ")
					);
				} else {
					this.$message.warning("请至少选择一项进行操作");
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding-bottom: 50px;
		min-height: calc(100% - 50px);
	}

	.title {
		font-size: 14px;
		font-weight: bold;
		color: #1c2438;
	}

	.filter_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0;

		.filter_search {
			display: flex;
			align-items: center;

			.filter_search_select {
				width: 80px;
				height: 36px;
				margin-right: 10px;

				::v-deep .el-input__inner {
					height: 36px;
				}

				::v-deep .el-input__icon {
					line-height: 36px;
				}
			}

			.filter_search_input {
				width: 350px;
				height: 36px;
				margin-right: 10px;

				::v-deep .el-input__inner {
					height: 36px;
				}
			}
			>div:not(:first-child) {
				margin-left: 20px;
			}
		}

		.filter_btns {
			display: flex;
			align-items: center;

			>div:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	.operations {
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			font-size: 14px;
			color: #406bff;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 13px;
			}
		}
	}

	.bottom_betch_operation {
		position: absolute;
		bottom: 0;
		display: flex;

		.betch_select {
			width: 130px;
			height: 36px;
			margin-right: 10px;

			::v-deep .el-input__inner {
				height: 36px;
			}

			::v-deep .el-input__icon {
				line-height: 36px;
			}
		}
	}
</style>
